import * as React from "react"
import {Link} from "gatsby";


const Button = ({ children, destination, style }) => {
    return (
      <div className='button' style={style}>
          <Link to={destination}>
          { children }
          </Link>
      </div>   
    )
}
  
export default Button