import * as React from "react"


const HeroImageBanner = ({ children }) => {
    return (
      <div className='hero-image-banner'>
          { children }
      </div>   
    )
}
  
export default HeroImageBanner