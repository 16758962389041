import * as React from "react"
import Layout from '../components/Layout';
import MainArea from '../components/MainArea';
import SideBySide from "../components/SideBySide";
import Banner from '../components/Banner';
import Button from '../components/Button';
import NextBanner from "../components/NextBanner";
import HeroImageBanner from "../components/HeroImageBanner";
import { StaticImage } from "gatsby-plugin-image";
import {Helmet} from 'react-helmet';

// assets
import BannerVideo from '../assets/content/banner.mp4';


// markup
const IndexPage = () => {
    return (
      <Layout>
        <Helmet>
            <title>Home | Bluetree Group</title>
            <meta name="description" content="Find out more about the Bluetree Graduate Management Programme" />
        </Helmet>         
        <Banner>
            <video autoPlay="autoplay" loop="loop">
              <source src={BannerVideo} type="video/mp4" />
            </video>                             
        </Banner> 
        <Button style={{marginTop: "-2em", maxWidth: "25em"}} destination="/register">Grow with us. Register today.</Button>           
        <MainArea>
          <SideBySide>
            <div>
              <h2>Introducing Bluetree Group</h2>
              <p>If you’re interested in building a career in an innovative, fast-paced, award-winning business, then look no further than Bluetree Group.</p>
              <p>Based in the heart of the Northern Powerhouse, our two-time Virgin Sunday Times Fast Track 100 ranked business has grown rapidly from humble beginnings. Founded in 1989, Bluetree Group now operates under the following brands: instantprint, Route1Print (printing services), Kingsbury Press (publishing) and Bluetree Medical (medical consumables), with a large range of products from flyers, business cards and high end publishing to Type IIR Surgical masks and transparent masks. Bluetree Group has a customer base of over 700,000, ranging from sole entrepreneurs through to the NHS.</p>
              <p>Don’t just take our word for it. We’ve attracted <a href='https://www.thetimes.co.uk/article/see-through-face-masks-are-a-clear-winner-with-young-382d7mfjl'>positive</a> <a href='http://www.rothbiz.co.uk/2021/02/news-7618-recruitment-surge-at-bluetree.html'>media</a> <a href='https://www.insidermedia.com/news/national/medilink-healthcare-business-awards-winners-revealed'>coverage</a> and numerous industry accolades along the way:</p>
            </div>
            <StaticImage width="800" src={"../assets/content/777A3845.JPG"} />
          </SideBySide>      
          <div className="image-banner">
            <img src="/awards/2016-Fast-Track-100-logo.png" />
            <img src="/awards/Fast-Track-2017.png" alt="2017 Fast Track" />
            <img src="/awards/1000-Companies-masthead-2017.png" alt="1000 Companies" />
            <img src="/awards/Sheffield-Business-Awards.png" alt="Sheffield Business Awards" />
            <img src="/awards/e2e.png" alt="e2e" />            
          </div>
          <HeroImageBanner>
            <div>
              <p>Our success is down to each and every one of our passionate colleagues, who are driven by our shared values and the opportunity to make an impact.</p>
              <p>We’re expanding at a rapid pace and are now looking for future leaders to join our graduate management programme.</p>
            </div>
            <StaticImage width="800" src={"../assets/content/celebration.jpg"} />
          </HeroImageBanner>
          <SideBySide>
            <StaticImage width="800" src={"../assets/content/777A8011.JPG"} />
            <div>
              <h2>Our Graduate Management Programme</h2>
              <p>The Bluetree Group Graduate Management Programme has been designed to shape the future leaders of our business, providing opportunities for talented individuals to grow alongside us.</p>
              <p>We’re looking for hard working individuals who embrace our core, aspirational, and permission to play values. This programme is for graduates who aren’t afraid of a challenge and who are excited about building a career in a business that thrives on innovation and identifying new opportunities. </p>
              <p>This year-long structured programme will combine management training sessions with hands-on, practical experience. Participants will also be challenged with a series of business critical projects.</p>
              <p>The outcome being confident and capable business leaders on a fast-track career path.</p>
            </div>
          </SideBySide>
          <Button style={{marginTop: "2em", marginBottom: "4em"}} destination="https://www.bluetreegroup.co.uk">Find out more about us. Visit our website today</Button>
          <NextBanner destination="/ourscheme">
            <strong>Next:</strong> Find out more about the scheme
          </NextBanner>
        </MainArea>
    </Layout>     
    )
  }
  
  export default IndexPage
  